import apis from "../../../../apis/client";

export const createLog = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.log.createLog(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const createPlanSubscription = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.log.createPlanSubscription(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const updateLog = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.log.updateLog(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getLog = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.log.getLog(payload);

      if (data) {
        dispatch(setLog(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const deleteLog = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.log.deleteLog(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const setLog = (data) => {
  return {
    type: "SET_LOG",
    payload: data,
  };
};
