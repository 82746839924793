import apis from "../../../../apis/client";

export const createReply = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.reply.createReply(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const updateReply = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.reply.updateReply(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getReply = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.reply.getReply(payload);

      if (data) {
        dispatch(setReply(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getSingleReply = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.reply.getReply(payload);

      if (data) {
        dispatch(setSingleReply(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const deleteReply = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.reply.deleteReply(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const setReply = (data) => {
  return {
    type: "SET_REPLY",
    payload: data,
  };
};
export const setSingleReply = (data) => {
  return {
    type: "SET_SINGLE_REPLY",
    payload: data,
  };
};
