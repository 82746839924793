import {
  signupWithPassword,
  generateOTP,
  verifyOTP,
} from "../../../redux/auth/auth-action";
import FeatherIcon from "feather-icons-react";
import {
  Text,
  Image,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  FormErrorMessage,
  FormControl,
  Select,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import OtherAuth from "../../auth/OtherAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { getMetaByPageName } from "../../../redux/cms/cms-actions";
import SEO from "../../../cms/SEO";
import { toast } from "react-toastify";
import { getAllPlan } from "redux/custom/subscription/plan/plan-actions";
import Payment from "components/Payment/Payment";

const Register = ({ pageName }) => {
  const data = useSelector((state) => state.cms.allSections[pageName]);
  function getImage(image) {
    if (image && image.includes("blob:")) {
      return image;
    } else {
      return process.env.REACT_APP_STORAGE_URL + image;
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [confirmShow, setConfirmShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleShowPass = () => setConfirmShow(!confirmShow);
  const [isShowEmailOTP, SetShowEmailOTP] = useState(false);
  const [metaData, setMetaData] = useState({});

  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [handlePaymentClick, setHandlePaymentClick] = useState(false);
  const [userData, setUserData] = useState({});

  const [filterAllPlan, setFilterAllPlan] = useState([]);

  let allPlan = useSelector((state) => state.plan.AllPlan);

  //===========SEO============
  // useEffect(() => {
  //   const getSEO = async () => {
  //     let data = await dispatch(getMetaByPageName({ pageName: "Register" }));
  //     if (data.code === 200) {
  //       setMetaData(data.data);
  //     }
  //   };
  //   getSEO();
  // }, []);
  //===========SEO============

  useEffect(() => {
    handleGetPlan();
  }, []);

  useEffect(() => {
    if (allPlan && allPlan.length > 0) {
      let filter = allPlan.filter((plan) => plan.type !== "User");
      setFilterAllPlan(filter);
      setSelectedPlanData(filter[0]);
      formik.setFieldValue("selectedPlanId", filter[0]._id);
    }
  }, [allPlan]);

  const handleGetPlan = async () => {
    try {
      await dispatch(getAllPlan());
    } catch (error) {
      console.log(error);
    }
  };

  const handlePhoneChange = async (value, country, e, formattedValue) => {
    const dialCountryCode = country.dialCode;
    const phoneNumber = value.replace(`${dialCountryCode}`, "");
    await formik.setFieldValue("mobile", phoneNumber);
    await formik.setFieldValue("countryCode", dialCountryCode);
    validateField("mobile", formik.values.mobile);
  };

  const validateField = (fieldName, value) => {
    try {
      validationRules.fields[fieldName].validateSync(value, {
        abortEarly: false,
      });
      formik.setFieldError(fieldName, undefined);
      formik.setFieldTouched(fieldName, true);
      return true;
    } catch (error) {
      formik.setFieldError(fieldName, error.errors[0]);
      formik.setFieldTouched(fieldName, true);
      return false;
    }
  };

  const GetEmailOTP = async () => {
    let emailValid = validateField("email", formik.values.email);
    let mobileValid = validateField("mobile", formik.values.mobile);
    if (!(emailValid && mobileValid)) {
      return;
    }
    let payload = {
      mobile: formik.values.mobile,
      email: formik.values.email.toLowerCase(),
      type: "Register",
    };

    try {
      let data = await dispatch(generateOTP(payload));

      if (data && data.code === 200) {
        // Swal.fire({
        //   title: "Success",
        //   text: "OTP successfully send to your email ID",
        //   icon: "success",
        // });
        toast.success("OTP successfully sent to your email ID");
        SetShowEmailOTP(true);
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      toast.error("Something went wrong!");
    }
  };

  function getItemList(item) {
    return [
      {
        id: item._id,
        name: item.name,
        quantity: 1,
        price: parseInt(item.price),
      },
    ];
  }

  const initialValues = {
    role: "",
    selectedPlanId: "",
    firstName: "",
    lastName: "",
    email: "",
    otp: "",
    mobile: "",
    countryCode: "",
    password: "",
    confirmPassword: "",
  };

  const validationRules = Yup.object({
    role: Yup.string().trim().required("Please select role"),
    firstName: Yup.string()
      .trim()
      // .min(3, "First name must be at least 3 characters")
      .max(25, "First name must be at most 25 characters")
      .required("First name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    lastName: Yup.string()
      .trim()
      // .min(3, "Last name must be at least 3 characters")
      .max(25, "Last name must be at most 25 characters")
      .required("Last name is required")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alpha numeric is allowed"),
    email: Yup.string()
      .trim()
      .lowercase()
      // .min(5, "Email must be at least 5 characters")
      .max(50, "Email must be at most 50 characters")
      .required("Email is required")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email is invalid"
      ),
    mobile: Yup.string()
      // .min(10, 'Mobile number must be 10 digits')
      // .max(10, 'Mobile number must be 10 digits')
      .required("Mobile number is required"),
    // .matches(/^\d{10}$/, 'Mobile number must be digits')
    // .matches(/^[6789]/, 'Mobile number is invalid')
    // .matches(/^[0-9]*$/, 'Only numeric value is allowed'),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password"), null], "Password must match"),
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP number must be 6 digits"),
  });

  const handleOnSubmit = async (values) => {
    let payload = {
      role: values.role,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      otp: values.otp,
      mobile: values.mobile,
      countryCode: values.countryCode,
      password: values.password,
      acceptTerms: true,
    };
    console.log(values.selectedPlanId);

    if (values.role === "TrainerApplicant" && values.selectedPlanId) {
      Object.assign(payload, { selectedPlanId: values.selectedPlanId });
    }

    try {
      let data = await dispatch(signupWithPassword(payload));

      if (data && data.code == 200) {
        // Swal.fire({
        //   title: "Congratulations",
        //   text: "Your account has been successfully created.",
        //   icon: "success",
        // });
        toast.success("Your account has been successfully created.");
        if (values.role === "TrainerApplicant") {
          setUserData(data.data);
          setHandlePaymentClick((state) => !state);
        } else {
          navigate("/");
        }
        formik.handleReset();
      } else {
        if (data.message) {
          // Swal.fire({
          //   title: "Error",
          //   text: data.message,
          //   icon: "error",
          // });
          toast.error(data.message);
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Error",
      //   text: "Something went wrong!",
      //   icon: "error",
      // });
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  const handleTrim = async (event) => {
    await formik.setFieldValue(event.target.name, event.target.value.trim());
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleOnSubmit,
    validationSchema: validationRules,
    validateOnChange: true,
  });

  return (
    <>
      <SEO metaData={metaData} />

      <section className="container mx-auto h-100">
        <div className="lg:flex md:flex items-center h-full lg:p-0 md:p-0 p-6">
          <div
            id="5561534387"
            className="dynamicStyle2 lg:w-1/2 md:w-1/2 lg:block md:block hidden"
          >
            <Image
              maxH="600"
              src={data ? getImage(data["5561534387"]) : ""}
              fallbackSrc={`${process.env.REACT_APP_STORAGE_URL_IND}Quarkly/default/1285952.jpg`}
              alt="loginImage"
              className="m-auto"
            />
          </div>
          <div className="smcust-shadow lg:w-1/2 md:w-1/2 lg:py-8 md:py-8 py-8 lg:px-12 md:px-6 px-5 ">
            <div>
              <p
                id="1482208496"
                className="dynamicStyle lg:text-4xl md:text-2xl text-2xl text-center font-semibold"
              >
                {" "}
                {data ? data["1482208496"] : "Register"}
              </p>

              <p className="  lg:text-base md:text-base text-sm text-center lg:mt-4 md:mt-4 mt-2">
                <span
                  id="6729677842"
                  dangerouslySetInnerHTML={{
                    __html: data ? data["6729677842"] : "no-data",
                  }}
                  className="dynamicStyle text333 opacity-70"
                ></span>{" "}
                <span className="text-black hov-underline cursor-pointer font-semibold">
                  <Link to="/user-agreement"> User Agreement</Link>
                </span>{" "}
                and{" "}
                <span className="text-black cursor-pointer hov-underline font-semibold">
                  <Link to="/privacy-policy"> Privacy policy</Link>
                </span>{" "}
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="lg:mt-16 md:mt-8 mt-8">
                  <div className="mb-6">
                    <h3
                      id="Register"
                      className="dynamicStyle fsize18 sm-fsize14 mb-2 text333 font-semibold"
                    >
                      Register As
                    </h3>
                    <FormControl
                      isInvalid={!!formik.errors.role && formik.touched.role}
                    >
                      <Select
                        name="role"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        onBlur={handleTrim}
                        placeholder="Select option"
                        colorScheme="secondary"
                      >
                        <option value="User">Client</option>
                        <option value="TrainerApplicant">Coach</option>
                      </Select>

                      {formik.touched.role && formik.errors.role && (
                        <FormErrorMessage>
                          {formik.errors.role}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </div>

                  {formik.values.role &&
                    formik.values.role === "TrainerApplicant" && (
                      // <div className="mb-6">
                      //   <h3
                      //     id="Register"
                      //     className="dynamicStyle flex items-center fsize18 sm-fsize14 mb-2 text333 font-semibold"
                      //   >
                      //     Select Plan{" "}
                      //     <Link to="/subscription" target="_blank">
                      //       <FeatherIcon
                      //         icon="info"
                      //         size={18}
                      //         className="ml-1 cursor-pointer text-warning"
                      //       />
                      //     </Link>
                      //   </h3>
                      //   <FormControl
                      //     isInvalid={
                      //       !!formik.errors.selectedPlanId &&
                      //       formik.touched.selectedPlanId
                      //     }
                      //   >
                      //     <Select
                      //       name="selectedPlanId"
                      //       value={formik.values.selectedPlanId}
                      //       onChange={(e) => {
                      //         formik.handleChange(e);
                      //         let selectedPlan = filterAllPlan.filter(
                      //           (plan) => plan._id === e.target.value
                      //         );
                      //         setSelectedPlanData(selectedPlan[0]);
                      //       }}
                      //       onBlur={handleTrim}
                      //       // placeholder="Select option"
                      //       colorScheme="secondary"
                      //     >
                      //       {filterAllPlan &&
                      //         filterAllPlan.length > 0 &&
                      //         filterAllPlan.map((plan) => {
                      //           return (
                      //             <>
                      //               <option
                      //                 value={plan._id}
                      //                 className="flex gap-2 py-1"
                      //               >
                      //                 <span>{plan.name}</span>
                      //                 <span>
                      //                   S$ {plan.price}/{plan.validity} days
                      //                 </span>
                      //               </option>
                      //             </>
                      //           );
                      //         })}
                      //     </Select>

                      //     {formik.touched.selectedPlanId &&
                      //       formik.errors.selectedPlanId && (
                      //         <FormErrorMessage>
                      //           {formik.errors.selectedPlanId}
                      //         </FormErrorMessage>
                      //       )}
                      //   </FormControl>
                      // </div>
                      <div>
                        Trainer onboarding will be starting shortly.
                        <span className="text-primary underline pr-1 cursor-pointer text-center">
                          <a href="mailto:info@fit2go.aisa">Click here</a>
                        </span>
                        and let us know if you are interested and exited towork
                        with us.
                      </div>
                    )}

                  {formik.values.role &&
                    formik.values.role !== "TrainerApplicant" && (
                      <div className="lg:mb-6 md:mb-4 mb-4 gap-4 lg:flex md:flex">
                        <div className="lg:w-1/2 md:w-1/2 w-full lg:mb-0 md:mb-0 mb-6">
                          <h3
                            id="7973984815"
                            className="dynamicStyle fsize18 sm-fsize14 text333 font-semibold"
                          >
                            {data ? data["7973984815"] : "First Name"}
                          </h3>
                          <FormControl
                            isInvalid={
                              !!formik.errors.firstName &&
                              formik.touched.firstName
                            }
                          >
                            <Input
                              name="firstName"
                              className="mt-2 fsize14"
                              placeholder="Enter your first name"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                            />
                            {formik.touched.firstName &&
                              formik.errors.firstName && (
                                <FormErrorMessage>
                                  {formik.errors.firstName}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div>

                        <div className="lg:w-1/2 md:w-1/2 w-full ">
                          <h3
                            id="9742539043"
                            className="dynamicStyle fsize18 sm-fsize14 text333 font-semibold"
                          >
                            {data ? data["9742539043"] : "Last Name"}
                          </h3>
                          <FormControl
                            isInvalid={
                              !!formik.errors.lastName &&
                              formik.touched.lastName
                            }
                          >
                            <Input
                              name="lastName"
                              className="mt-2 fsize14"
                              placeholder="Enter your last name"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                            />
                            {formik.touched.lastName &&
                              formik.errors.lastName && (
                                <FormErrorMessage>
                                  {formik.errors.lastName}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div>
                      </div>
                    )}

                  {formik.values.role &&
                    formik.values.role !== "TrainerApplicant" && (
                      <div className="mb-6">
                        <h3
                          id="1075383670"
                          className="dynamicStyle fsize18 sm-fsize14 text333 font-semibold"
                        >
                          {data ? data["1075383670"] : "Mobile Number"}
                        </h3>
                        <FormControl
                          isInvalid={
                            !!formik.errors.mobile && formik.touched.mobile
                          }
                        >
                          <PhoneInput
                            name="mobile"
                            country={"sg"}
                            enableSearch={true}
                            className="mt-2 phoneinput w-full mt-2 themefamily"
                            onChange={handlePhoneChange}
                          />
                          {formik.touched.mobile && formik.errors.mobile && (
                            <FormErrorMessage>
                              {formik.errors.mobile}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </div>
                    )}

                  {formik.values.role &&
                    formik.values.role !== "TrainerApplicant" && (
                      <div className="mb-6">
                        <h3
                          id="0009601630"
                          className="dynamicStyle fsize18 sm-fsize14 text333 font-semibold"
                        >
                          {data ? data["0009601630"] : "Email Address"}
                        </h3>
                        <FormControl
                          isInvalid={
                            !!formik.errors.email && formik.touched.email
                          }
                        >
                          <InputGroup>
                            <Input
                              name="email"
                              className="mt-2 fsize14"
                              placeholder="Enter your email address"
                              pr="5.5rem"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={handleTrim}
                            />
                            {!isShowEmailOTP && (
                              <InputRightElement
                                pr="0rem"
                                width="6.5rem"
                                paddingTop="4"
                              >
                                <Button
                                  h="1.75rem"
                                  size="sm"
                                  onClick={GetEmailOTP}
                                >
                                  Send OTP
                                </Button>
                              </InputRightElement>
                            )}
                          </InputGroup>

                          {formik.touched.email && formik.errors.email && (
                            <FormErrorMessage>
                              {formik.errors.email}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        {/* {isShowEmailOTP && ( */}
                        <div className="mt-4 resendotp-t">
                          <p className=" fsize14 sm-fsize12 text333 font-semibold mb-1">
                            {"Enter OTP"}
                          </p>
                          <FormControl
                            isInvalid={
                              !!formik.errors.otp && formik.touched.otp
                            }
                          >
                            <OTPInput
                              name="otp"
                              value={formik.values.otp}
                              onChange={(e) => {
                                formik.setFieldValue("otp", e);
                              }}
                              OTPLength={6}
                              otpType="number"
                              className="numb-otp"
                              inputclassName="themefamily"
                            />
                            {formik.touched.otp && formik.errors.otp && (
                              <FormErrorMessage>
                                {formik.errors.otp}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                          {isShowEmailOTP && (
                            <div className="mt-2">
                              <ResendOTP onResendClick={() => GetEmailOTP()} />
                            </div>
                          )}
                        </div>
                        {/* )} */}
                      </div>
                    )}
                  {formik.values.otp &&
                    formik.values.otp.toString().length === 6 &&
                    isShowEmailOTP && (
                      <div className="lg:flex md:flex gap-4">
                        <div className="lg:w-1/2 md:w-1/2 w-full md:mb-0 lg:mb-0 mb-4">
                          <h3
                            id="9019711980"
                            className="dynamicStyle fsize18 sm-fsize14 text333 font-semibold"
                          >
                            {data ? data["9019711980"] : "Password"}
                          </h3>
                          <FormControl
                            isInvalid={
                              !!formik.errors.password &&
                              formik.touched.password
                            }
                          >
                            <InputGroup>
                              <Input
                                name="password"
                                autoComplete={false}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={handleTrim}
                                pr="4.5rem"
                                type={show ? "text" : "password"}
                                className="mt-2 fsize14"
                                placeholder="Enter your password"
                              />
                              <InputRightElement paddingTop="4">
                                <div
                                  onClick={() => {
                                    handleClick();
                                  }}
                                >
                                  {" "}
                                  {show ? (
                                    <FeatherIcon
                                      icon="eye"
                                      size={16}
                                      onClick={() => {
                                        handleClick();
                                      }}
                                    />
                                  ) : (
                                    <FeatherIcon
                                      size={16}
                                      icon="eye-off"
                                      onClick={() => {
                                        handleClick();
                                      }}
                                    />
                                  )}
                                </div>
                              </InputRightElement>{" "}
                            </InputGroup>
                            {formik.touched.password &&
                              formik.errors.password && (
                                <FormErrorMessage>
                                  {formik.errors.password}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </div>

                        <div className="lg:w-1/2 md:w-1/2 w-full">
                          <div>
                            <h3
                              id="7017828564"
                              className="dynamicStyle fsize18 sm-fsize14 text333 font-semibold"
                            >
                              {data ? data["7017828564"] : "  Confirm Password"}
                            </h3>
                            <FormControl
                              isInvalid={
                                !!formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                              }
                            >
                              <InputGroup>
                                <Input
                                  autoComplete={false}
                                  name="confirmPassword"
                                  value={formik.values.confirmPassword}
                                  onChange={formik.handleChange}
                                  onBlur={handleTrim}
                                  pr="4.5rem"
                                  type={confirmShow ? "text" : "password"}
                                  className="mt-2 fsize14"
                                  placeholder="Enter your password"
                                />
                                <InputRightElement paddingTop="4">
                                  <div
                                    onClick={() => {
                                      handleShowPass();
                                    }}
                                  >
                                    {" "}
                                    {confirmShow ? (
                                      <FeatherIcon
                                        icon="eye"
                                        size={16}
                                        onClick={() => {
                                          handleShowPass();
                                        }}
                                      />
                                    ) : (
                                      <FeatherIcon
                                        size={16}
                                        icon="eye-off"
                                        onClick={() => {
                                          handleShowPass();
                                        }}
                                      />
                                    )}
                                  </div>
                                </InputRightElement>{" "}
                              </InputGroup>
                              {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword && (
                                  <FormErrorMessage>
                                    {formik.errors.confirmPassword}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* <div id="9548220046" className="dynamicStyle"> */}
                  {formik.values.role &&
                    formik.values.role !== "TrainerApplicant" && (
                      <div>
                        <Payment
                          onClick={handlePaymentClick}
                          noButton={true}
                          button={""}
                          buttonClass={""}
                          buttonColor={""}
                          itemList={getItemList(selectedPlanData)}
                          planId={selectedPlanData && selectedPlanData._id}
                          userId={userData._id}
                          successUrl={"becameacoach"}
                          cancelUrl={"becameacoach"}
                          isDisabled={true}
                        />

                        <Button
                          type="submit"
                          colorScheme="primary"
                          className="  w-full mt-8 sm-fsize16px"
                          size="lg"
                        >
                          {formik.values.role === "TrainerApplicant"
                            ? "Register & Pay"
                            : "Register"}
                        </Button>
                      </div>
                    )}
                </div>
              </form>
              <p className=" lg:text-base md:text-base text-sm text-center mt-12">
                <span id="4109885762" className="dynamicStyle">
                  {" "}
                  {data ? data["4109885762"] : " Already have an Account?"}
                </span>
                <span className="text-black hov-underline cursor-pointer font-semibold pl-2">
                  <Link to="/login">Login</Link>
                </span>{" "}
              </p>

              {/* <Box className="sm-px-30px" position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4">
                  <p id="1785147843" className="dynamicStyle">
                    {data ? data["1785147843"] : "or"}{" "}
                  </p>
                </AbsoluteCenter>
              </Box>

              <OtherAuth /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
