import apis from "../../../../apis/client";

export const createPost = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.post.createPost(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const updatePost = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.post.updatePost(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getPost = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.post.getPost(payload);

      if (data) {
        dispatch(setPost(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getSinglePost = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.post.getPost(payload);

      if (data) {
        dispatch(setSinglePost(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const deletePost = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.post.deletePost(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const updatePollVote = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.post.updatePollVote(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const setPost = (data) => {
  return {
    type: "SET_POST",
    payload: data,
  };
};
export const setSinglePost = (data) => {
  return {
    type: "SET_SINGLE_POST",
    payload: data,
  };
};
