import apis from "../../../../apis/client";

export const createComment = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.comment.createComment(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const updateComment = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.comment.updateComment(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getComment = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.comment.getComment(payload);

      if (data) {
        dispatch(setComment(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const getSingleComment = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.comment.getComment(payload);

      if (data) {
        dispatch(setSingleComment(data.data));
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};
export const deleteComment = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.comment.deleteComment(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const setComment = (data) => {
  return {
    type: "SET_COMMENT",
    payload: data,
  };
};
export const setSingleComment = (data) => {
  return {
    type: "SET_SINGLE_COMMENT",
    payload: data,
  };
};
