import apis from "../../../../apis/client";

export const togglePostLike = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.like.togglePostLike(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const toggleCommentLike = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.like.toggleCommentLike(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const toggleReplyLike = (payload) => {
  let record;
  return async (dispatch) => {
    try {
      let { data } = await apis.like.toggleReplyLike(payload);

      if (data) {
        record = data;
      }
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
    return record;
  };
};

export const setLike = (data) => {
  return {
    type: "SET_LIKE",
    payload: data,
  };
};
